import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";


import Christian from "./components/Christian";
import Hindu from "./components/Hindu";
import Buddhist from "./components/Buddhist";
import Muslim from "./components/Muslim";
import Other from "./components/Other";

import ArticleView from "./components/ArticleView";
import NotFound from "./components/NotFound";
import Horoscope from "./components/Horoscope";
import Disclaimer from "./components/Disclaimers";
import About from "./components/About";



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>

            <Route path = "/christian" element = { <Christian/> } ></Route>
            <Route path = "/muslim" element = { <Muslim/> } ></Route>
            <Route path = "/hindu" element = { <Hindu/> } ></Route>
            <Route path = "/buddhist" element = { <Buddhist/> } ></Route>
            <Route path = "/other" element = { <Other/> } ></Route>

            <Route path = "/read/:id"  element = { <ArticleView></ArticleView> } ></Route>

            <Route path = "/horoscope" element = { <Horoscope/> } ></Route>

            <Route path = "/disclaimer" element = { <Disclaimer></Disclaimer> } ></Route>
            <Route path = "/about" element = { <About></About> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App