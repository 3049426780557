import {React, useState, useEffect} from "react";



  
  
function ArticleCard ({id, title, category, date, monthyear, coverimage, shortdescription}){
  return (
      <>
      <div className="sm:w-1/2  mb-10 px-4 flex justify-center items-center">
      <section className="blog_section layout_padding">
        <div className="container">
           {/**<div className="row"> */}
            {/**<div className="col-md-6"> */}
              <div className="box bg-gray-200 dark:bg-gray-700">
                <div className="img-box  bg-white dark:bg-gray-700">
                  {/**rounded-t-lg */}
                  <img
                  className=""
                    src={coverimage}
                    alt=""
                  />
                  <h4 className="blog_date">
                  {date} <br />
                  {monthyear}
                  </h4>
                </div>
                <div className="detail-box bg-gray-200 dark:bg-gray-700 text-base text-black-500 dark:text-white">
                <p className="text-blue-400 font-semibold text-sm">🔹 {category}</p>
                  <h5>{title}</h5>
                  <p>{shortdescription}</p>
                  <a className="flex justify-center items-center" href={"/read/"+id} key={id}>Read more</a> 
                </div>
              </div>
            {/**</div> */}
          {/** </div>*/}
        </div>
      </section>
    </div>
      </>
  )
}


  function ArticleListing({category}){
        const [searchItem, setSearchItem] = useState('')
      
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [items, setItems] = useState([]);
      
        useEffect(() => {
          const url = `https://goldfish-app-asx3v.ondigitalocean.app/gift/${category}`;
          fetch(url)
            .then(res => res.json())
            .then(
              (result) => {
                setIsLoaded(true);
                setItems(result);
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                setIsLoaded(true);
                setError(error);
              }
            )
      
        }, [category])
      
        if (error) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Loading...</div>;
        } else if (items === null) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">No Article found in this category. Come back later !</div>;
        } else {
        return (
            <>
            <section className="body-font">
                <div className="container px-5 py-6 mx-auto">
                    <div className="px-2 py-2 flex justify-center items-center">
                        <input  type="text" placeholder="Search article by title " className="input input-primary dark:bg-gray-700 w-full max-w-md"  value={searchItem} onChange={event => setSearchItem(event.target.value)}></input>
                    </div>
                    <div className=""  style={{margin:"0 auto", padding:"10px", maxWidth:"1200px"}}>
                        <div className="px-4 py-6">
                            <div className="flex flex-wrap -mx-4 -mb-10">
                                {items.filter((article) => //items here
                                {
                                    if(searchItem === "") {
                                    return article;
                                    }
                                    else if 
                                    (article.Title.toLowerCase().includes(searchItem.toLowerCase())) {
                                    return article;
                                    }
                                    else return null
                    
                                }).map(article => <ArticleCard 
                                    key={article.Id} 
                                    id={article.Id} 
                                    title={article.Title} 
                                    category= {article.Category} 
                                    coverimage={article.CoverImageUrl} 
                                    date={article.Date}
                                    monthyear={article.MonthAndYear} 
                                    >

                                    </ArticleCard>
                                )}                               
                            </div>
                        </div>

                    </div>
                </div>
            </section> 
            </>
        );}
  }

  export default ArticleListing;