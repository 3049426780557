import React from "react";
function About() {
    return (
      <>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b> About Us</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                  <p>
                 At <a className="text-sky-500 hover:text-sky-600 font-bold text-base" href="https://arfusoft.com/" target="_blank" rel="noreferrer"> Arfusoft</a>, 
                 we are always eager to show our friends and family members that they are essential to us. From that idea that one should sometimes thank the people around him for their support, we created this
                  <span className="font-bold"> Special Gift Ideas website </span> 
                  to recommend unique gifts that can bring joy to those you care about. <br />  
                    <span> Our software company (Arfusoft) has years of experience in developing:</span>
                  </p>
                  <ul className="space-y-4">
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> frontend</code> applications
                      </p>
                    </li>
                    <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        Web
                        <code className="text-sm font-bold "> backend/server</code> applications
                      </p>
                    </li>
                                <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Mobile</code> applications for Android and IOS
                      </p>
                    </li>
                                            <li className="flex items-center">
                      <svg className="h-6 w-6 flex-none fill-none bg-base-100  stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="11" />
                        <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                      </svg>
                      <p className="ml-4">
                        <code className="text-sm font-bold ">Desktop</code> applications for Win and MAC
                      </p>
                    </li>
                  </ul>
                  <p className="text-base"><span className="text-sky-500 hover:text-sky-600 font-bold text-base">Our area of expertise </span> is <span className="text-purple-500 font-bold text-base">data-intensive fullstack web applications.</span>  </p>
                  <p className="text-base">For more information, please get in touch with us by email at <a className="text-sky-400 hover:text-sky-600 font-bold" href="mailto:contact@arfusoft.com">contact@arfusoft.com</a> .</p>
                  <p className="text-base">You can also visit our official website <a className="text-sky-400 hover:text-sky-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">here</a> .</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default About;